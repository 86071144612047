import React, { FC } from 'react';
import FreshiiLayouts from '../../../components/PagesLayouts/FreshiiLayouts';
import SectionTitle from '../../../components/common/SectionTitle';
import ListItem from '../../../components/pagesComponents/CaseStudy/ListItem';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import Span from '../../../components/pagesComponents/CaseStudy/Span';
import FixedButton from '../../../components/common/FixedButton';

const TechnicalSummary: FC = () => {
    return (
        <FreshiiLayouts>
            <div className="statflo-case-study__text statflo-case-study__text--large">
                <SectionTitle type="primary" title="Technical summary" />
                <ListItem margin="mt">
                    Implemented complex logic of customization of products to allow for a wide range of customer preferences and options.
                </ListItem>
                <ListItem>
                    Utilized microservice infrastructure on<Span type="bold">AWS ECS</Span>to ensure the scalability and reliability of the platform.
                </ListItem>
                <ListItem>
                    Streamlined customer ordering and promotions by integrating with a new menu, loyalty, and campaign/promo provider<Span type="bold">Order House</Span>.
                </ListItem>
                <ListItem>Ensured secure and efficient payment processing by integrating with payment provider<Span type="bold">CardConnect</Span>.</ListItem>
                <ListItem>
                    Offered convenient delivery options to customers by integrating with white-label delivery provider<Span type="bold">DoorDash</Span>.
                </ListItem>
                <ListItem>
                    Integrated with menu provider<Span type="bold">OLO</Span>to ensure accurate and up-to-date menu offerings.
                </ListItem>
                <ListItem>
                    Simplified payment processing for customers by implementing multiple provider split payment functionality.
                </ListItem>
                <ListItem>
                    Improved security and streamlined customer login by implementing<Span type="bold">SSO</Span>with<Span type="bold">Firebase</Span>.
                </ListItem>
                <ListItem>
                    Ensured secure and reliable transactions by implementing fraud prevention logic.
                </ListItem>
                <ListItem>
                    Improved customer service and streamlined operations by automating refund procedures
                </ListItem>
                <ListItem>
                    Implemented new responsive UI design on the Web with<Span type="bold">React</Span>,<Span type="bold">Typescript</Span>,<Span type="bold">Redux Thunk</Span>, and<Span type="bold">Redux Toolkit</Span>, with<Span type="bold">Apollo Client</Span>to power the<Span type="bold">Apollo GraphQL</Span>.
                </ListItem>
                <ListItem>
                    Offered a seamless mobile experience for customers by developing native apps for<Span type="bold">Android</Span>on<Span type="bold">Kotlin</Span>and<Span type="bold">iOS on Swift UI</Span>.
                </ListItem>
                <ListItem>
                    Provided Freshii customers the ability to purchase and redeem gift cards by integrating with gift card provider<Span type="bold">Givex</Span>.
                </ListItem>
                <ListItem>
                    Gained insights into customer behavior and improved business strategy by integrating with analytics platforms<Span type="bold">MixPanel</Span>, platforms<Span type="bold">FB Pixel</Span>, and<Span type="bold">Google Analytics</Span>.
                </ListItem>
                <Links />
            </div>
            <FixedButton/>
        </FreshiiLayouts>
    );
};

export default TechnicalSummary;
